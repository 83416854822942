<template>
  <div class="flex justify-center text-white relative bg-[#11091A]">
    <img
      :src="bg"
      alt="bg"
    >
    <div class="absolute top-56/100 md:top-56/100 flex flex-col items-center gap-4 text-2xl">
      <div
        v-for="domain in domains"
        class="w-50 md:w-120 py-1 flex flex-col justify-center gap-2 hover:underline"
      >
        <img
          :src="domain.title"
          alt="domain"
        >
        <a
          :href="`http://${domain.url}`"
          target="_blank"
          class="flex justify-center"
        >
          <img
            :src="domain.img"
            alt="domain"
          >
        </a>
      </div>
      <a
        :href="telegramUrl"
        target="_blank"
        class="px-4 mt-10"
      >
        <img
          :src="telegram"
          alt="telegram"
        >
      </a>
    </div>
  </div>
</template>
<script setup>
import bg from '@/assets/domain/bg.png'
import domain1 from '@/assets/domain/domain-2.png'
import domain2 from '@/assets/domain/domain-1.png'
import title1 from '@/assets/domain/title-1.png'
import title2 from '@/assets/domain/title-2.png'
import telegram from '@/assets/domain/telegram.png'
import { useDecal } from '@/composables/useDecal'

const { telegramUrl } = useDecal()

const domains = [
  {
    title: title1,
    img: domain1,
    url: 'vel725.com'
  },
  {
    title: title2,
    img: domain2,
    url: 'vel223.com'
  },
]
</script>
